import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { HeaderImgSection } from "../Header/HeaderImgSection";
import img from '../../images/contactus.png'


function Contact() {

    useEffect(() => {
        document.title = "Contact || OZVASERVICE"
    }, []);

    let contact = [
        {
            firstLater: "Address",
            text: "47 Walters Road, Blacktown NSW-2148"
        },
        {
            firstLater: "Address",
            text: "Level 3, 169 Fullarton Road, Dulwich, Adelaide, South Australia SA 5065"
        },
        {
            firstLater: "Phone",
            text: "+61 468 720 642"
        },
        {
            firstLater: "Phone",
            text: "+61 870 941 934"
        },
        {
            firstLater: "ABN",
            text: "65 666 642 299"
        },
        {
            firstLater: "E-mail",
            text: "info@ozvaservice.com.au"
        },


    ]
    return (
        <>
            <HeaderImgSection title="Contact" img={img} />

            <Container className="my-5">
                <Row>
                    <Col lg={4} className="mb-lg-0 mb-3">
                        <h3 className="fw-semibold mb-3">Contact Details</h3>
                        {/* <p>
                            207/100 Gungahlin Place,
                            <br />
                            GUNGAHLIN, ACT - 2912
                        </p> */}
                        {contact.map((el, i) => {
                            return (
                                <div key={i} className=" mb-3">
                                    <span className="fw-bolder fs-5">{el.firstLater} : </span>
                                    <span>{el.text}</span>
                                </div>
                            )
                        })}
                    </Col>
                    <Col lg={4}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13265.742021869337!2d150.8956719!3d-33.7752502!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1299a0077a6923%3A0xe1b11fde8c646551!2sOZVA%20Service!5e0!3m2!1sen!2sin!4v1698919783283!5m2!1sen!2sin" width="100%" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.4356791248756!2d150.89309697650475!3d-33.77524571422059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1299a587da3347%3A0xc1ca32b31edd496d!2s47%20Walters%20Rd%2C%20Blacktown%20NSW%202148%2C%20Australia!5e0!3m2!1sen!2sin!4v1698751786119!5m2!1sen!2sin" width="100%" height="350" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118106.58331791215!2d73.09068542737236!3d22.322240635313253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc8ab91a3ddab%3A0xac39d3bfe1473fb8!2sVadodara%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1686800422411!5m2!1sen!2sin" width="100%" height="350"  allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                    </Col>
                    <Col lg={4}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3270.873831860421!2d138.62193487650967!3d-34.93470107505482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0ceb0d9da8b69%3A0xa521ddedb81f4659!2s3%2F169%20Fullarton%20Rd%2C%20Dulwich%20SA%205065%2C%20Australia!5e0!3m2!1sen!2sin!4v1733902929630!5m2!1sen!2sin" width="100%" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                    </Col>
                </Row>
            </Container>

        </>
    );
}
export default Contact;
