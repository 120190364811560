import React from "react";
import { faTwitter, faFacebookF, faGooglePlusG, faInstagram, faLinkedin, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Topbar() {
  return (
    <>
      <div className="top-bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="social-media text-white">
                <ul>
                  <li>
                    <i className="fas fa-faEnvelope fa-rotate-90"></i> info@ozvaservice.com.au
                  </li>
                  <li>
                    ||
                  </li>
                  <li>
                    <a target="_blank" href="https://www.facebook.com/ozvaservice/">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://twitter.com/OzvaServic">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                        <FontAwesomeIcon icon={faGooglePlusG}  />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                        <FontAwesomeIcon icon={faLinkedinIn}  />
                    </a>
                  </li> */}
                  <li>
                    <a target="_blank" href="https://www.instagram.com/ozvaservice/">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="contact-details">
                <ul>
                  <li>
                    <i className="fas fa-phone fa-rotate-90"></i> +61 468 720 642
                  </li>
                  <li>
                    ||
                  </li>
                  <li>
                    <i className="fas fa-phone fa-rotate-90"></i> +61 870 941 934
                  </li>
                  <li>
                    ||
                  </li>
                  <li>
                    <i className="fas fa-phone fa-rotate-90"></i>ABN: 65 666 642 299
                  </li>
                  <li>
                    ||
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i> New South Wales, NSW-2148
                  </li>
                  <li>
                    ||
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i> Adelaide, South Australia SA 5065
                  </li>
                  {/* <li>
                    <i className="fas fa-map-marker-alt"></i> 47 Walters Road, Blacktown NSW-2148
                  </li>
                  <li>
                    ||
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i> Level 3, 169 Fullarton Road, Dulwich, Adelaide, South Australia SA 5065
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Topbar;
